<template>
  <b-row>
    <b-col cols="12">
      <div style="margin-bottom: 20px;">
        <b-button
          variant="gradient-primary"
          @click="() => this.$router.push({ name: 'account-add' })"
        >
          <feather-icon icon="PlusSquareIcon" class="mr-50" />
          เพิ่มข้อมูล
        </b-button>
      </div>
      <account-table />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import accountTable from "./Table.vue";
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,

    accountTable,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
