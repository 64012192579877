<template>
  <b-card title="บัญชีธนาคารสำหรับตรวจสอบ *** ข้อมูลนี้สำคัญ โปรดใช้งานอย่างระมัดระวัง">
    <b-overlay :show="loading" rounded="sm">
      <!-- search input -->
      <div>
        <SearchComponent @SubmitData="searchSubmit" />
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="accountList"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          //enabled: true,
          //selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          //selectionInfoClass: 'custom-class',
          //selectionText: 'rows selected',
          //clearSelectionText: 'clear',
          //disableSelectInfo: true, // disable the select info panel on top
          //selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectionChanged"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-page-change="paginationFunc"
        @on-per-page-change="itemPerpageFunc"
        @on-sort-change="sortFunc"
      >
        <!-- Selected: Delete -->
        <div slot="selected-row-actions">
          <b-button variant="gradient-danger">ลบ</b-button>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Use -->
          <span v-if="props.column.field === 'use'" class="text-nowrap">
            <span v-if="props.row.use === true">
              <b-badge variant="success" class="badge-glow">
                เปิดใช้งาน
              </b-badge>
            </span>
            <span v-else-if="props.row.use === false">
              <b-badge variant="danger" class="badge-glow">
                ปิดใช้งาน
              </b-badge>
            </span>
          </span>

          <span v-else-if="props.column.field === 'bank'" class="text-nowrap">
            <img
              :src="require(`@/assets/images/bank/${props.row.bank}.png`)"
              width="30"
              style="margin-right:10px"
            />
            {{ props.row.bank.toUpperCase() }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ดูรายละเอียด'"
                :to="`account-detail/${props.row.id}`"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                variant="gradient-warning"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'แก้ไข'"
                :to="`account-edit/${props.row.id}`"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                variant="gradient-success"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'เปิดใช้งาน'"
                v-if="props.row.use === false"
                @click="showOpenModal(props.row.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ปิดใช้งาน'"
                v-if="props.row.use === true"
                @click="showCloseModal(props.row.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ลบ'"
                @click="showDeleteModal(props.row.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- Column: loading -->
        <template slot="loadingContent">
          <b-spinner variant="primary" label="Text Centered" />
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="perPageValue"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ totalCount }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="pageValue"
                :total-rows="totalCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  VBTooltip,
  BCard,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
// import dataDummy from "./data";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BaseAPIURL from "@core/app-config/baseURL";
import perPageValue from "@/constants/perPagedropdown";
import configFunction from "@/constants/functional";
import store from "@/store/index";
import SearchComponent from "../components/Search.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    SearchComponent,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    // vendorList: Array,
    // table: String,
    // where: String,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      // API
      collectionAPI: "/account-msts", //ชื่อ API
      whereLimit: "&_limit=", // condition  ตั้งต้น, ตัวอย่างการเขียน -> "&_limit=5",
      whereSortList: [],
      whereSortCondition: "",
      wherePagination: "",
      whereSearchCondition: "",
      whereQueryDefaultCondition: "", // Qeury เริ่มต้น, ตัวอย่างการเขียน -> "&delete=false",
      whereCondition: "",
      // Table
      pageLength: 0,
      pageValue: 1,
      dir: false,
      columns: [
        {
          label: "เลขบัญชี",
          field: "accNo",
        },
        {
          label: "ชื่อบัญชี",
          field: "accName",
        },
        {
          label: "ธนาคาร",
          field: "bank",
        },
        {
          label: "บัญชีผู้ใช้",
          field: "username",
        },
        {
          label: "เปิดใช้งาน",
          field: "use",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      // accountList: dataDummy.accountList, // import from data Dummy
      accountList: [],
      totalCount: 0,
      moment,
      BaseAPIURL,
      perPageValue,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      }
    },
  },
  created() {
    // Set Page Data
    this.pageLength = this.perPageValue[0];
    this.whereLimit = this.whereLimit + `${this.perPageValue[0]}`;
    // Get Data
    this.getDataTotal();
    this.fetchData();
  },
  methods: {
    selectionChanged(e) {
      console.log(e);
    },
    sendData() {
      this.btnLoading = true;
    },
    getDataTotal() {
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      return useJwt
        .get(this.collectionAPI, `count?${this.whereCondition}`)
        .then((res) => {
          if (res.status === 200) {
            return (this.totalCount = res.data);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Not Found`,
              icon: "XCircleIcon",
              variant: "danger",
              text: `${err}`,
            },
          });
        });
    },
    fetchData() {
      this.loading = true;

      // รวม Query
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      return useJwt
        .query(this.collectionAPI, this.whereCondition)
        .then((res) => {
          if (res.status === 200) {
            console.log("res", res);
            res && res.data && res.data.length > 0
              ? (this.accountList = res.data.map((item, index) => {
                  return {
                    id: item.id ? item.id : "",
                    accNo: item.accNo ? item.accNo : "",
                    accName: item.accName ? item.accName : "",
                    username: item.username ? item.username : "",
                    bank: item.bank ? item.bank : "",
                    use: item.use ? item.use : false,
                  };
                }))
              : (this.accountList = []);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: err,
            },
          });
        })
        .finally(() => {
          this.loading = false;
          console.log("this.accountList", this.accountList);
        });
    },
    searchSubmit(e) {
      this.loading = true;
      const searchData = JSON.parse(JSON.stringify(e));
      const accNo =
        searchData && searchData.accNo
          ? `accNo_contains=${searchData.accNo}`
          : "";
      const accName =
        searchData && searchData.accName
          ? `accName_contains=${searchData.accName}`
          : "";
      const username =
        searchData && searchData.username
          ? `username_contains=${searchData.username}`
          : "";
      // ต้องเรียงเอง
      this.whereSearchCondition = `${accNo ? `&${accNo}` : ""}${
        accName ? `&${accName}` : ""
      }${username ? `&${username}` : ""}`;

      this.wherePagination = "";

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    itemPerpageFunc(data) {
      this.loading = true;
      this.whereLimit = `&_limit=${data.currentPerPage}`;
      this.wherePagination = "";

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    paginationFunc(data) {
      this.loading = true;
      if (data.currentPage > 1) {
        this.wherePagination = `&_start=${(data.currentPage - 1) *
          this.pageLength}`;
      } else {
        this.wherePagination = "";
      }

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    sortFunc(data) {
      this.loading = true;
      this.whereSortCondition = "&_sort=";
      for (let i = 0; i < data.length; i++) {
        if (data.length === 1 || i === data.length - 1) {
          this.whereSortCondition =
            this.whereSortCondition + `${data[i].field}:${data[i].type}`;
        } else if (i < data.length - 1) {
          this.whereSortCondition =
            this.whereSortCondition + `${data[i].field}:${data[i].type}` + ",";
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Unsuccessful`,
              icon: "XCircleIcon",
              variant: "danger",
              text: "เกิดเหตุการณ์ผิดปกติ กรุณา refresh หน้าจอ",
            },
          });
          return false;
        }
      }
      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    onOpen(id) {
      useJwt
        .update("/account-msts", id, { use: true })
        .then((res) => {
          console.log("จาก api", res);
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `สำเร็จ`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `บันทึกข้อมูลสำเร็จ`,
              },
            });
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `เกิดข้อผิดพลาด`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `${err}`,
            },
          });
        })
        .finally(() => {
          this.getDataTotal().then(() => {
            this.fetchData();
          });
        });
    },
    onClose(id) {
      useJwt
        .update("/account-msts", id, { use: false })
        .then((res) => {
          console.log("จาก api", res);
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `สำเร็จ`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `บันทึกข้อมูลสำเร็จ`,
              },
            });
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `เกิดข้อผิดพลาด`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `${err}`,
            },
          });
        })
        .finally(() => {
          this.getDataTotal().then(() => {
            this.fetchData();
          });
        });
    },
    onDelete(id) {
      useJwt
        .delete("/account-msts", id)
        .then((res) => {
          console.log("จาก api", res);
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `สำเร็จ`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `ลบข้อมูลสำเร็จ`,
              },
            });
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `เกิดข้อผิดพลาด`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `${err}`,
            },
          });
        })
        .finally(() => {
          this.getDataTotal().then(() => {
            this.fetchData();
          });
        });
    },
    showOpenModal(id) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการเปิดใช้งานบัญชีนี้ใช่หรือไม่ ?", {
          title: "ยืนยันการเปิดใช้งาน",
          size: "sm",
          okVariant: "success",
          okTitle: "ยืนยัน",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.onOpen(id);
          }
        });
    },
    showCloseModal(id) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการปิดใช้งานบัญชีนี้ใช่หรือไม่ ?", {
          title: "ยืนยันการปิดใช้งาน",
          size: "sm",
          okVariant: "danger",
          okTitle: "ยืนยัน",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.onClose(id);
          }
        });
    },
    showDeleteModal(id) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบข้อมูลนี้ใช่หรือไม่ ?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          okVariant: "danger",
          okTitle: "ลบ",
          cancelTitle: "ยกเลิก",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.onDelete(id);
          }
        });
    },
  },
};
</script>
